@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --eligo-primary-50: #fdf5f7;
    --eligo-primary-100: #fbebee;
    --eligo-primary-500: #db3558;
    --eligo-primary-700: #a42842;
    --eligo-contrast-50: #FFFFFF;
    --eligo-contrast-100: #FFFFFF;
    --eligo-contrast-500: #FFFFFF;
    --eligo-contrast-700: #666666;
    --eligo-secondary-500: #67D1CF;
    --eligo-error: rgb(255, 215, 215);
  }
}

/* // Montserrat */

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  src: url(assets/fonts/Montserrat-Italic-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  src: url(assets/fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}


/* // Recoleta */
@font-face {
  font-family: "Recoleta";
  font-weight: 100;
  src: url("assets/fonts/Recoleta-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Recoleta";
  font-weight: 300;
  src: url("assets/fonts/Recoleta-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Recoleta";
  font-weight: 500;
  src: url("assets/fonts/Recoleta-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Recoleta";
  font-weight: 600;
  src: url("assets/fonts/Recoleta-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Recoleta";
  font-weight: 700;
  src: url("assets/fonts/Recoleta-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Recoleta";
  font-weight: 800;
  src: url("assets/fonts/Recoleta-Black.woff2") format("woff2");
}


body {
  font-size: 0.8125em;
  font-family: "Montserrat";
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
  font-weight: 400;
}

h1,
h2 {
  font-family: "Recoleta";
  font-weight: 500;
}

h4 {
  font-weight: 500;
  font-family: "Montserrat";
}

p {
  font-weight: 400;
  line-height: 1.5em;
  margin: 10px 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.button {
  border-radius: 5px;
  font-weight: 600;
  font-size: 1em;
  line-height: 16px;
  margin-top: 10px;
  text-transform: uppercase;
  height: 35px;
}

.button.secondary {
  background-color: var(--eligo-secondary-500);
}

.button.button--disabled {
  pointer-events: none;
  cursor: default;
}

.button.button--full {
  width: 100%;
  padding: .5em 2.2em;
  background: var(--eligo-primary-600);
  border: 1px solid var(--eligo-contrast-500);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
}

.widget-main-window {
  background: var(--eligo-primary-500);
  color: var(--eligo-contrast-500);
  padding: 21px 25px;
  border-radius: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* IMAGE CAROUSEL */
.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 314px;
  margin: 0 auto;
  position: relative;
}

.carousel img {
  opacity: 0;
  position: absolute;
  height: 100%;
  transition: opacity 2s ease-in-out;
}

.carousel img.active {
  opacity: 1;
}

.carousel .pulse {
  position: absolute;
  width: 14px;
  height: 14px;
}

.widget-main-window.sm .carousel {
  height: 262px;
}

.copyright {
  position: absolute;
  bottom: 4px;
  left: 14px;
  font-size: 8px;
  color: var(--eligo-contrast-500);
}

/* LIVE / PULSE */

.live {
  padding: 0px 5px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: -10px;
  font-weight: 500;
  width: 70px;
  font-size: 13px;
  line-height: 24px;
  color: var(--eligo-contrast-500);
  border: 1px solid;
}

.pulse {
  width: 10px;
  height: 10px;
  flex: 0 0 10px;
  border-radius: 50%;
  position: relative;
  margin: 0 7px;
  background-color: var(--eligo-primary-500);
}

.pulse.secondary {
  background-color: var(--eligo-secondary-500);
}

.pulse::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  scale: 1.5;
  background-color: inherit;
  opacity: .7;
  border-radius: 50%;
  inset: 0;
  z-index: 0;
  transform-origin: center center;
  transition: transform 0.2s ease;
  animation: pulse 2s ease-in-out infinite;
}

/* CLOSE BUTTON */

.close-button {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 25px;
  right: 25px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}

.close-button svg {
  margin-top: 1px;
}

/* STEPPER */

.stepper-container {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 48px;
  background: white;
  padding: 4px;
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  border-radius: 8px;
}

.stepper-container::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.stepper-container::-webkit-scrollbar-track {
  background: transparent;
  height: 2px;
}

.stepper-container::-webkit-scrollbar-thumb {
  background: rgb(56, 56, 56);
}

.stepper-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stepper-btn {
  height: 40px;
  background: var(--eligo-primary-600);
  color: var(--eligo-contrast-500);
  border-radius: 4px;
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
  display: flex;
}

.stepper-btn.button.button--disabled:not(.stepper-btn:nth-child(1)) {
  background: var(--eligo-contrast-500);
  color: gray
}

.stepper-btn svg {
  margin-right: 5px;
}

.stepper-btn span {
  font-size: 0.87rem;
}

.month-selection {
  @extend .flex-center;
  gap: 1.25rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

// CALENDAR

.calendar {
  text-align: center;
}

.calendar-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 30px;
  gap: 10px;
}

.calendar-weekday {
  font-size: 15px;
  font-weight: 400;
}

.calendar-day {
  background: var(--eligo-contrast-500);
  color: black;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 30px;
  font-weight: 500;
}

.calendar-day-selected {
  background: var(--eligo-primary-600);
  color: white;
}

.calendar-day.today {
  border: 3px solid var(--eligo-primary-700);
}

// TIMESLOTS

.timeslot-list {
  margin-top: 0.5rem;
}

.timeslot-list::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.timeslot-list::-webkit-scrollbar-track {
  background: transparent;
  height: 2px;
}

.timeslot-list::-webkit-scrollbar-thumb {
  background: rgb(56, 56, 56);
}

.timeslot-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.timeslot-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 40px;
  gap: 10px;
}

.timeslot-name {
  padding-left: 10px;
  font-weight: 400;
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: -0.25rem;
}

.timeslot-empty {
  padding: 0.5rem;
  margin: .5rem 0;
  background: var(--eligo-contrast-500);
  opacity: .7;
  color: black;
}

.timeslot {
  background: white;
  color: black;
  font-weight: 300;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.107);
}

// FORM GROUP

.form-group {
  @extend .flex-vertical-center;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
  padding: 0 5px;
}

.form-control {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid var(--eligo-contrast-500);
  color: black;

  &:focus {
    outline: none;
  }

  &.error {
    &::placeholder {
      color: red;
    }

  }
}

.info-step {
  margin-top: 10px;
}

.info-step label {
  padding-left: 5px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 12px;
}

.info-step :is(input, textarea) {
  padding: 10px;
  border-radius: 8px 8px 0 0;
  border-width: 2px;
}

// LOADING

.eligo-loading {
  height: 100px;
  width: 100px;

  .eligo-loading__backdrop {
    @extend .flex-center;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: 2000;
    background: rgba($color: var(--eligo-contrast-50), $alpha: 0.8);
  }
}

.eligo-loading svg g circle {
  fill: var(--eligo-primary-500);
}

// ELIGO LIVE

.eligo-live {
  display: flex;
  align-items: center;
  border: 1px solid white;
  border-radius: 4px;
  height: 32px;
  max-width: max-content;
  padding-right: 10px;
  margin: auto;
  overflow: hidden;
}

.eligo-live img {
  height: 20px;
}

.eligo-live>div:nth-child(1) {
  height: 32px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


// SUCCESS PAGE
.success-page .final-info {
  display: grid;
  margin-top: 15px;
  padding: 10px;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  grid-template-columns: 55% 45%;
  font-weight: 400;
}

.success-page .final-info p {
  padding-right: 15px;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.success-page :not(.final-info) {
  font-weight: 500;
}

.success-page label {
  opacity: .7;
}


// INSTANT MEETING
.instant-meeting {
  display: flex;
  flex-direction: column;
  align-items: center;


  h2 {
    width: 100%;
  }

}

.live-container {
  --border-radius: .125rem;
  @extend .flex-center;
  margin-top: 1.25rem;
  padding: .5rem;
  width: 200px;
  background-color: var(--eligo-secondary-500);
  border-radius: var(--border-radius);
  border: 2px solid var(--eligo-contrast-500);
}

.live-logo-container {
  display: flex;

  .logo-container {
    padding: 0.5rem;
    background: var(--eligo-primary-500);
    border-radius: var(--border-radius) 0 0 var(--border-radius);

    img {
      height: 1.25rem;
    }
  }

  .live {
    border: 1px solid var(--eligo-primary-500);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    margin-left: 0;
    background: var(--eligo-contrast-500);
    color: var(--eligo-primary-500);
  }
}

.modal {
  @extend .flex-center;
  position: fixed;
  inset: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;

  &-backdrop {
    // absolute inset-0 z-0 bg-gray-500 bg-opacity-75 transition-opacity;
    position: absolute;
    inset: 0;
    background: rgba(220, 220, 220, 0.74);
    backdrop-filter: blur(2px);
    transition: background .5s ease-in-out;
  }

  &-container {
    color: var(--eligo-contrast-500);
    animation: fade-up 1s ease-in-out;
    position: absolute;
    bottom: 15%;
    min-width: 500px;
    background: var(--eligo-primary-500);
    border-radius: 10px;
    box-shadow: 0 0 20px 10px rgba(107, 107, 107, 0.193);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--eligo-contrast-500);

    h3 {
      font-weight: 600;
      padding: 0 1rem;
      color: var(--eligo-contrast-500);
    }

    button {
      background: rgba(0, 0, 0, 0);
      border-radius: 50%;
      padding: .375rem;
      margin-left: auto;
      display: inline-flex;
      align-items: center;
    }
  }

  &-content {
    // py-4 px-2 md:px-6 space-y-3
    padding: 0.5rem 1rem;
    margin: .75rem 0;

  }

  &.sm {
    .modal-container {
      bottom: 0;
      width: 100%;
    }

    .modal-header {
      padding: 0.5rem 0;
      border-radius: 8px 8px 0 0;

      h3 {
        padding: 0 .5rem;
      }
    }
  }

}

@media (max-width: 767px) {
  .modal {
    &-container {
      bottom: 0;
      width: 100%;
      min-width: none;
    }
  }
}

.page {
  width: 100%;
  height: 100%;
  padding: 2rem 2.5rem;
  border-radius: 0.375rem;
  background: var(--eligo-primary-500);
  color: var(--eligo-contrast-500);
}

input {
  font-size: 16px;
}

input::placeholder {
  font-size: 14px;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}