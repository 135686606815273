.appointment-item {
    display: flex;
    margin-bottom: 10px;
    border-radius: 20px;
    height: 95px;
    background: white;
    overflow: hidden;
}

.appointment-item:hover {
    opacity: .8;
    cursor: pointer;
}

.appointment-item.reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.appointment-item__img {
    height: 100%;
    object-fit: contain;
}

.appointment-item__content {
    padding: 10px;
    display: flex;
    text-align: start;
    align-items: center;
}
